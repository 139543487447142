import React, { useContext, useState } from 'react'
import PageContext from '@context'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Box,
  List,
  Collapse,
  ListItem,
  ListItemText,
  Divider,
  useMediaQuery,
} from '@material-ui/core'
import KeyboardArrowRightIcon from '@react-svgs/keyboard_arrow_right.svg'
import ExpandMore from '@react-svgs/expand_more.svg'
import renderBloks from '@renderBloks'
import { ThemeProvider } from '@material-ui/styles'
import ACC_THEME from '@themes'
import multilinkToUrl from '@helpers/multilink-to-url'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  outer: {
    display: 'flex',
    flexDirection: ({ sidebarAlignment }) =>
      `column${sidebarAlignment === 'right' ? '-reverse' : ''}`,
    [theme.breakpoints.up('lg')]: {
      flexDirection: ({ sidebarAlignment }) =>
        `row${sidebarAlignment === 'right' ? '-reverse' : ''}`,
    },
  },
  dropdownItemsContainer: {
    alignContent: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    padding: '35px 32px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  drawerHeadline: {
    padding: '0',
    marginBottom: '18px',
    '& span': {
      color: theme.palette.common.white,
      fontSize: 18,
      lineHeight: '25px',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& svg': {
      fill: theme.palette.common.white,
      height: '28px',
      width: '28px',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  drawerMenuList: {
    '& a': {
      border: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    width: '100%',
    justifyContent: 'space-between',
    color: theme.palette.common.white,
    display: 'block',
  },
  verticalRuleWrapper: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 12px',
  },
  verticalRule: {
    height: '85%',
    borderLeft: `1px solid ${theme.palette.border.gray}`,
  },
}))

const TopNavTabButtonData = ({ blok }) => {
  const {
    path,
    bottomItems,
    sidebarItems,
    items,
    _uid,
    headline,
    isLink,
    sidebarAlignment,
  } = blok
  const isInEditor = useContext(PageContext).isInEditor
  const classes = useStyles(blok)
  const [open, setOpen] = useState(false)
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  const headingUrl = !!path && multilinkToUrl(path, isInEditor)
  const headingHaslink =
    !!path && (!!path.url || (!!path.story && !!path.story.url))
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <SbEditable key={_uid} content={blok}>
      <ThemeProvider theme={ACC_THEME}>
        <Box>
          {!handHeldDevice ? (
            <Box className={classes.outer}>
              {renderBloks(sidebarItems, { sidebarAlignment })}
              {!!sidebarItems?.length && (
                <Box className={classes.verticalRuleWrapper}>
                  <Box className={classes.verticalRule} />
                </Box>
              )}
              <Box className={classes.dropdownItemsContainer}>
                {renderBloks(items)}
                {renderBloks(bottomItems, {
                  isNavigationItem: true,
                })}
              </Box>
            </Box>
          ) : (
            <List component="nav">
              <ListItem
                button
                onClick={handleClick}
                className={classes.drawerHeadline}
              >
                <ContentContainer
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  {headingHaslink && isLink ? (
                    <a className={classes.link} href={headingUrl}>
                      <ListItemText primary={headline} />
                    </a>
                  ) : (
                    <ListItemText primary={headline} />
                  )}
                  {!!items &&
                    !!items.length &&
                    (open ? <ExpandMore /> : <KeyboardArrowRightIcon />)}
                </ContentContainer>
              </ListItem>
              <Divider
                style={{
                  backgroundColor: '#FFF',
                }}
              />
              {!!items && !!items.length > 0 && (
                <Collapse
                  in={open}
                  timeout="auto"
                  unmountOnExit
                  className={classes.drawerMenuList}
                >
                  <ContentContainer>
                    <div style={{ paddingLeft: '32px' }}>
                      {sidebarAlignment === 'left' && renderBloks(sidebarItems)}
                      {!!items[0].items &&
                        items[0].items[0].cards === undefined && (
                          <>
                            {renderBloks(items)}
                            {!!items && <Divider />}
                          </>
                        )}
                      {renderBloks(bottomItems, {
                        isNavigationItem: true,
                        isMobileNavContent: true,
                      })}
                      {sidebarAlignment === 'right' &&
                        renderBloks(sidebarItems)}
                    </div>
                  </ContentContainer>
                </Collapse>
              )}
            </List>
          )}
        </Box>
      </ThemeProvider>
    </SbEditable>
  )
}

export default TopNavTabButtonData
